<template>
  <div>
    <v-card flat :loading="loading">
      <v-card-text>
        <div >
          <v-toolbar color="transparent" dense flat>
            <v-chip
              v-if="selectedPallets.length > 0"
              class="mx-1"
              small
              outlined
            >
              Selected Pallets: {{ selectedSSCC }}
            </v-chip>
            <v-chip
              v-if="selectedPallets.length > 0"
              class="mx-1"
              small
              outlined
            >
              Selected Cartons {{ selectedCartons }}
            </v-chip>
            <v-chip
              v-if="selectedPallets.length > 0"
              @click="confirmDeletePallets = true"
              class="mx-1"
              small
              outlined
              color="red"
            >
              <v-icon small left>delete</v-icon> Delete Selected Pallets
            </v-chip>
            <v-spacer></v-spacer>
            <v-switch
              dense
              hide-details
              v-model="displayStuffed"
              label="Display Stuffed"
              @change="getAllStock()"
              class="mr-2"
              style="font-size: 12px"
            ></v-switch>
            <v-chip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    v-on="on"
                    :loading="emailingExport"
                    @click="emailExport()"
                  >
                    <v-icon small> email </v-icon>
                  </v-btn>
                </template>
                Email Stock Export
              </v-tooltip>
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    v-on="on"
                    :loading="downloadingExport"
                    @click="initiateStockDownload()"
                  >
                    <v-icon small>download</v-icon>
                  </v-btn>
                </template>
                Download Export
              </v-tooltip> -->
              <v-icon small color="grey">search</v-icon>
              <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
                @change="searchKey++"
              ></v-text-field>
            </v-chip>
          </v-toolbar>
          <v-card-text
            style="
              max-height: 75vh;
              overflow-y: auto;
              max-width: 100%;
              overflow-x: auto;
            "
            class="mt-0"
          >
            <v-row justify="end">
              <v-col cols="12" sm="12" class="mt-0 pt-0">
                <v-list dense subheader>
                  <v-divider></v-divider>
                  <!-- <v-progress-linear
                    indeterminate
                    color="primary"
                    v-if="loading"
                  ></v-progress-linear> -->
                  <div :style="{ width: palletTableWidth }" class="mx-1">
                    <table :width="'100%'">
                      <tr>
                        <td
                          class="text-center mx-0 px-0"
                          style="
                            cursor: grab;
                            border-right: 1px solid grey;
                            min-height: 20px;
                            min-width: 20px;
                          "
                          v-for="(header, index) in palletHeaders"
                          :key="index"
                          :width="header.width ?? '100px'"
                        >
                          <div>
                            <v-row align="center" justify="center" class="py-2">
                              <b style="font-size: 11px">{{ header.text }}</b>
                              <v-btn
                                v-if="!header.disableSort"
                                class="ml-1"
                                icon
                                x-small
                                @click="changeSortBy(header.value)"
                              >
                                <v-icon
                                  v-if="sortBy != header.value"
                                  x-small
                                  color="grey"
                                  >arrow_upward</v-icon
                                >
                                <v-icon v-else-if="sortDesc" x-small
                                  >arrow_downward</v-icon
                                >
                                <v-icon v-else small>arrow_upward</v-icon>
                              </v-btn>
                              <v-badge
                                v-if="!header.disableFilter"
                                :content="
                                  filter[header.value]
                                    ? filter[header.value].length
                                    : false
                                "
                                dense
                                :value="
                                  filter[header.value] &&
                                  filter[header.value].length > 0
                                "
                                color="primary"
                                offset-y="10"
                              >
                                <v-menu
                                  offset-y
                                  left
                                  :close-on-content-click="false"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"
                                      x-small
                                      color="grey"
                                      icon
                                      ><v-icon small>filter_alt</v-icon></v-btn
                                    >
                                  </template>
                                  <v-card
                                    style="
                                      background: var(
                                        --v-modal-base
                                      ) !important;
                                      border-radius: 20px;
                                    "
                                  >
                                    <v-card-text>
                                      <v-list
                                        dense
                                        style="
                                          background: var(
                                            --v-modal-base
                                          ) !important;
                                          border-radius: 20px;
                                        "
                                      >
                                        <v-text-field
                                          hide-details
                                          rounded
                                          dense
                                          outlined
                                          clearable
                                          prepend-inner-icon="search"
                                          placeholder="Search"
                                          autofocus
                                          v-model="searchHeaders[header.value]"
                                          @input="updateSearchKey()"
                                        ></v-text-field>
                                        <v-list
                                          dense
                                          style="
                                            max-height: 50vh;
                                            overflow-y: auto;
                                            background: var(
                                              --v-modal-base
                                            ) !important;
                                          "
                                          :key="searchKey"
                                        >
                                          <v-list-item
                                            v-for="(
                                              item, index
                                            ) in headerOptions(header.value)"
                                            :key="index"
                                          >
                                            <v-list-item-action class="pr-0">
                                              <v-checkbox
                                                v-model="filter[header.value]"
                                                :value="item.value"
                                                hide-details
                                                dense
                                                @change="calculateDisplayData()"
                                              ></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                {{ item.name }}
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item
                                            v-if="
                                              headerOptions(header.value)
                                                .length == 0
                                            "
                                          >
                                            <v-list-item-content
                                              class="text-center"
                                            >
                                              <span style="font-size: 12px"
                                                >No results found</span
                                              >
                                            </v-list-item-content>
                                          </v-list-item>
                                        </v-list>
                                      </v-list>
                                    </v-card-text>
                                  </v-card>
                                </v-menu>
                              </v-badge>
                            </v-row>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <v-divider></v-divider>
                    <v-virtual-scroll
                      v-if="filterDisplayData.length > 0"
                      :bench="10"
                      :items="filterDisplayData"
                      :height="'575px'"
                      :width="'100%'"
                      item-height="25"
                      class="px-0 mx-0"
                    >
                      <template v-slot:default="{ item }">
                        <div
                          class="text-center my-0 py-0"
                          :style="{
                            display: 'flex',
                            'flex-direction': 'row',
                            width: '100%',
                            'background-color': 'transparent',
                          }"
                        >
                          <td
                            v-for="(header, index) in palletHeaders"
                            :key="index"
                            :style="{ width: header.width ?? '100px' }"
                            class="text-center my-0 py-0"
                          >
                            <div v-if="header.value == 'action'">
                              <v-btn
                                @click="selectPallet(item)"
                                x-small
                                v-if="itemSelected(item.id)"
                                style="border: none"
                                color="success"
                                icon
                              >
                                <v-icon small>check_circle_outline</v-icon>
                              </v-btn>
                              <v-btn
                                x-small
                                @click="selectPallet(item)"
                                v-else
                                icon
                                color="grey"
                              >
                                <v-icon small>check</v-icon>
                              </v-btn>
                            </div>
                            <div v-else-if="header.value == 'status'">
                              <v-chip
                                @click="
                                  addToFilter(item[header.value], header.value)
                                "
                                x-small
                                outlined
                                :color="getPalletStatusColor(item.status)"
                              >
                                {{ item.status }}
                              </v-chip>
                            </div>
                            <div
                              v-else-if="
                                header.value == 'inspectionManifestDate' ||
                                header.value == 'intakeTime'
                              "
                            >
                              <v-chip
                                v-if="item[header.value]"
                                small
                                class="primaryText--text"
                                outlined
                                @click="
                                  addToFilter(item[header.value], header.value)
                                "
                                style="border: none"
                              >
                                {{ formatDate(item[header.value]) }}
                              </v-chip>
                            </div>

                            <div v-else>
                              <v-chip
                                v-if="item[header.value]"
                                @click="
                                  addToFilter(item[header.value], header.value)
                                "
                                small
                                class="primaryText--text"
                                outlined
                                style="
                                  border: none;
                                  font-size: 11px;
                                  z-index: 1;
                                "
                              >
                                {{ item[header.value] }}
                              </v-chip>
                            </div>
                          </td>
                        </div>
                      </template>
                    </v-virtual-scroll>
                  </div>
                  <div
                    class="containerMessage"
                    v-if="!loading && displayData.length == 0"
                  >
                    <h3 style="color: grey">No Results Found</h3>
                  </div>
                </v-list>
              </v-col>
              
            </v-row>
          </v-card-text>
          <v-col cols="12" class="text-center" v-if="!loading && !$vuetify.breakpoint.mobile">
                    <el-pagination
                      style="color: var(--v-primaryText-base)"
                      :current-page.sync="page"
                      :pager-count="5"
                      :page-size.sync="params.limit"
                      :page-sizes="[25, 30, 50, 100]"
                      :layout="
                        !$vuetify.breakpoint.mobile
                          ? 'prev, pager, next, jumper, total'
                          : 'prev, pager, next'
                      "
                      :total="params.total"
                    >
                    </el-pagination>
                  </v-col>
        </div>
        <!-- <div v-else>
          <valhalla-loading height="75vh" />
        </div> -->
      </v-card-text>
    </v-card>

    <v-dialog v-model="confirmDeletePallets" width="600px">
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="confirmSelectedPointModal = false">X</v-btn> -->
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 12px">
            Are you sure you would like to delete the
            {{ selectedSSCC }} selected pallets?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmDeletePallets = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="deletePallets"
              :loading="deletingPallets"
              >Save</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
  name: "RawInventory",
  data: () => ({
    confirmDeletePallets: false,
    deletingPallets: false,
    displayData: [],
    downloadingExport: false,
    filter: {},
    filterKey: 100,
    filteredValues: [],
    displayStuffed: false,
    emailingExport: false,
    loading: false,
    page: 1,
    params: {
      offset: 0,
      limit: 25,
        total: 0,
    },
    palletHeaders: [
      {
        text: "",
        value: "action",
        disableSort: true,
        disableFilter: true,
        width: "75px",
      },
      {
        text: "Status",
        value: "status",
        allowSort: true,
        allowFilter: true,
        width: "100px",
      },
      {
        text: "Location",
        value: "locationCode",
        allowSort: true,
        allowFilter: true,
        width: "100px",
      },
      {
        text: "CTO No",
        value: "ctoNo",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Barcode",
        value: "sscc",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Seq.",
        value: "sequenceNo",
        allowSort: true,
        allowFilter: false,
        width: "90px",
      },
      {
        text: "Pal. Qty",
        value: "palletQuantity",
        allowSort: true,
        allowFilter: false,
        width: "120px",
      },
      {
        text: "Ctns",
        value: "cartonQuantity",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Cust. Ref",
        value: "customerContainerRef",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Comm.",
        value: "commodityCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Var.",
        value: "varietyCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Grade",
        value: "grade",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Count",
        value: "countSize",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Pack",
        value: "packCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Mark",
        value: "mark",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Inv. Code",
        value: "inventoryCode",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Prod. Char",
        value: "productCharacteristic",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Target Market",
        value: "targetMarket",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "Target Region",
        value: "targetRegion",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "Target Country",
        value: "targetCountry",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "PUC",
        value: "puc",
        allowSort: true,
        allowFilter: true,
        width: "120px",
      },
      {
        text: "Producer",
        value: "producerName",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Orch.",
        value: "orchard",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "PHC",
        value: "phc",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Packhouse Name",
        value: "packhouseName",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Insp. Date",
        value: "inspectionManifestDate",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Insp. Age",
        value: "inspectionAge",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Orig. Intake Waybill",
        value: "originalWaybillNo",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Intake Waybill",
        value: "waybillNo",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Loc. Intake Date",
        value: "intakeTime",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Loc. Intake Age",
        value: "age",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Nett",
        value: "nettWeight",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Gross",
        value: "grossWeight",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Document Name",
        value: "documentName",
        allowSort: true,
        allowFilter: true,
        width: "220px",
      },
      {
        text: "Document Time",
        value: "batchFileTime",
        allowSort: true,
        allowFilter: true,
        width: "220px",
      },
    ],
    palletStatus: [
      { name: "In Stock", icon: "fact_check", color: "orange" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "In Transit", icon: "local_shipping", color: "teal" },
      { name: "Rejected", icon: "verified", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Stuff Discrepancy", icon: "error", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Instruction Sent", icon: "email", color: "indigo" },
    ],
    search: null,
    searchHeaders: {},
    searchKey: 250,
    selectedPallets: [],
    selectedPalletKey: 100,
    stock: [],
    stockQuery: undefined,
    stockTimeout: undefined,
    sortBy: null,
    sortDesc: false,
  }),
  watch: {
    "params.search": {
      immediate: true,
      async handler() {
        this.params = {
          ...this.params,
          offset: 0,
        };
        this.getAllStock();
      },
    },
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      this.getAllStock();
    },
    "params.limit": {
      immediate: true,
      async handler() {
        this.getAllStock();
      },
    },
    search(value) {
      this.getAllStock();
    },
  },
  computed: {
    filterDisplayData() {
      let result = this.displayData;
      // if (this.search) {
      //   result = result.filter(
      //     (x) =>
      //       x.sscc.toLowerCase().includes(this.search.toLowerCase()) ||
      //       (x.locationCode &&
      //         x.locationCode
      //           .toLowerCase()
      //           .includes(this.search.toLowerCase())) ||
      //       // (x.sequenceNo && x.sequenceNo.toLowerCase().includes(this.search.toLowerCase())) ||
      //       // (x.age && x.age.toLowerCase().includes(this.search.toLowerCase())) ||
      //       (x.bookingContainerNo &&
      //         x.bookingContainerNo
      //           .toLowerCase()
      //           .includes(this.search.toLowerCase())) ||
      //       (x.commodityCode &&
      //         x.commodityCode
      //           .toLowerCase()
      //           .includes(this.search.toLowerCase())) ||
      //       (x.varietyCode &&
      //         x.varietyCode
      //           .toLowerCase()
      //           .includes(this.search.toLowerCase())) ||
      //       (x.mark &&
      //         x.mark.toLowerCase().includes(this.search.toLowerCase())) ||
      //       (x.grade &&
      //         x.grade.toLowerCase().includes(this.search.toLowerCase())) ||
      //       (x.targetMarket &&
      //         x.targetMarket
      //           .toLowerCase()
      //           .includes(this.search.toLowerCase())) ||
      //       (x.phc &&
      //         x.phc.toLowerCase().includes(this.search.toLowerCase())) ||
      //       (x.puc &&
      //         x.puc.toLowerCase().includes(this.search.toLowerCase())) ||
      //       (x.orchard &&
      //         x.orchard.toLowerCase().includes(this.search.toLowerCase()))
      //   );
      // }
      return result;
    },
    palletTableWidth() {
      let result = 0;
      this.palletHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 100);
      });
      return result + "px";
    },
    selectedCartons() {
      return this.stock
        .filter((x) => this.selectedPallets.includes(x.id))
        .map((x) => parseInt(x.cartonQuantity))
        .reduce((a, b) => a + b, 0);
    },
    selectedSSCC() {
      return [
        ...new Set(
          this.stock
            .filter((x) => this.selectedPallets.includes(x.id))
            .map((x) => x.sscc)
        ),
      ].length;
    },
  },
  created() {
    this.setFilter();
    this.getAllStock();
  },
  methods: {
    addToFilter(value, header) {
      if (this.filter[header].includes(value)) {
        this.filter[header] = this.filter[header].filter((x) => x != value);
      } else {
        this.filter[header].push(value);
      }
      this.filterKey++;
      this.calculateDisplayData();
    },
    calculateDisplayData() {
      let result = [...this.stock];
      let appliedfilter = Object.keys(this.filter).filter(
        (x) => this.filter[x].length > 0
      );
      this.filteredValues = appliedfilter;
      for (let i = 0; i < appliedfilter.length; i++) {
        if (appliedfilter[i] == "grade") {
          result = result.filter(
            (x) =>
              this.filter[appliedfilter[i]].includes(x.formattedClass) ||
              this.filter[appliedfilter[i]].includes(x.grade)
          );
        } else {
          result = result.filter((x) =>
            this.filter[appliedfilter[i]].includes(x[appliedfilter[i]])
          );
        }
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      this.displayData = result;

      this.filterKey++;
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.calculateDisplayData();
    },
    async deletePallets() {
      this.deletingPallets = true;
      let data = await this.$API.deleteStock(this.selectedPallets);
      this.deletingPallets = false;
      this.$message.success("Pallets Deleted Successfully");
      this.confirmDeletePallets = false;
      this.stock = this.stock.filter(
        (x) => !this.selectedPallets.includes(x.id)
      );
      this.selectedPallets = [];
      this.calculateDisplayData();
    },
    async downloadExport() {
      this.downloadingExport = true;
      let data = await this.$API.getStockExport();
      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        data;
      hiddenElement.target = "_blank";
      hiddenElement.download =
        "Pallet_Overview_" + new Date().getTime() + ".xlsx";
      hiddenElement.click();
      this.downloadingExport = false;
    },
    async emailExport() {
      try {
        this.emailingExport = true;
        let data = await this.$API.getStockExportEmail();
        this.emailingExport = false;
        this.$message.success("Export Email Sent Successfully");
      } catch (e) {
        this.$message.error("Error Sending Email");
        this.emailingExport = false;
      }
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd/mm/yyyy");
      }
      return result;
    },
    getPalletStatusColor(status) {
      let find = this.palletStatus.find((x) => x.name == status);
      return find ? find.color : "grey";
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.stock
            .filter((x) => x[value])
            .map((x) => ({ name: x[value], value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      return result;
    },
    async getAllStock() {
      if (this.stockTimeout) {
        clearTimeout(this.stockTimeout);
      }
      if (this.loading && this.stockQuery) {
        this.stockQuery.abort();
      }
      this.loading = true;
      let params = {
        hide: [],
      };
      if (!this.displayStuffed) {
        params.hide.push("Stuffed");
      }
      params = {
        ...params,
        ...this.params,
        search: this.search,
      };
      this.stockTimeout = setTimeout(async () => {
        this.stockQuery = new AbortController();
        const signal = this.stockQuery.signal;
        let result = await this.$API.getActiveStockOverview(params,signal);
        this.stock = result.data
        this.params.total = result.total
        this.calculateDisplayData();
        this.loading = false;
      }, 750);

      this.loading = true;
      

      // let data = await this.$API.getActiveStockOverview(params);
      // this.stock = data;
      
      // this.loading = false;
    },
    initiateStockDownload() {
      this.downloadExport();
    },
    itemSelected(id) {
      let result = this.selectedPallets.includes(id);
      return result;
    },
    resetFilters() {
      this.setFilter();
      this.calculateDisplayData();
    },
    selectPallet(item) {
      // let findSSCC = this.stock
      //   .filter((x) => x.sscc == item.sscc)
      //   .map((x) => x.id);
      if (this.selectedPallets.includes(item.id)) {
        this.selectedPallets = this.selectedPallets.filter(
          (x) => x != item.id
        );
      } else {
        this.selectedPallets.push(item.id);
        // this.selectedPallets = [...this.selectedPallets, ...findSSCC];
      }
      this.selectedPalletKey++;
    },
    setFilter() {
      let values = this.palletHeaders.map((x) => x.value);
      this.filter = {};
      values.forEach((x) => {
        this.filter[x] = [];
      });
    },
  },
};
</script>
<style scoped>
.containerMessage {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: -17px;
  background: #444444;
  color: #fff;
}
</style>